.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.text-input {
  min-width: 89px;
  height: 11px;
  border-radius: 8px;
}

.tag-container {
  align-items: center;
  /* border: 1px solid #BDBDBD; */
  border-radius: 8px;
  margin: 5px;
  padding: 5px;
  min-width: 25px;
  min-height: 40px;
}

.tag-input {
  border: 0;
  flex: 1;
  outline: none;
}

.tag-icons {
  display: flex;
  margin-left: 2px;
}

.tag-icon {
  cursor: pointer;
  margin-left: 1px;
}

.tag-placeholder {
  /* background-color: #e0e0e0; */
  border-radius: 8px;
  margin-left: 2px;
  padding: 5px;
  border: 1px solid #bdbdbd;
}

.animate {
  animation: shake 0.5s infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.pulse {
  animation: pulse 1s infinite ease-in-out alternate;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.09);
  }

  50% {
    transform: scale(1.2);
  }

  75% {
    transform: scale(1.09);
  }

  100% {
    transform: scale(1);
  }
}

.react-tagsinput {
  border-radius: 8px !important;
  padding: 8px 12px !important;
}

.react-tagsinput-tag {
  background-color: transparent !important;
  color: white !important;
  border: none !important;
  font-size: medium !important;
  border-radius: 8.08px !important;
  padding: 4px 12px !important;
  margin: 0px !important;
}

.tag-auto-complete .react-tagsinput {
  border-radius: 8px !important;
  padding: 8px 12px !important;
}

.tag-auto-complete .react-tagsinput-tag {
  background-color: transparent !important;
  color: lightgray !important;
  border: none !important;
  font-size: medium !important;
  border-radius: 8.08px !important;
  padding: 8px 12px !important;
}

/* ------ input tags --------- */

.react-tags {
  position: relative;
  padding: 10px 14px;
  border: 0px !important;
  border-radius: 8px !important;
  background: #ffffff;
  /* shared font styles */
  font-size: 1rem;
  line-height: 1.2;
  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-active {
  border-color: #4f46e5;
}

.react-tags.is-disabled {
  opacity: 0.75;
  background-color: #eaeef2;
  /* Prevent any clicking on the component */
  pointer-events: none;
  cursor: not-allowed;
}

.react-tags.is-invalid {
  border-color: #fd5956;
  box-shadow: 0 0 0 2px rgba(253, 86, 83, 0.25);
}

.react-tags__label {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.react-tags__list {
  /* Do not use display: contents, it's too buggy */
  display: inline;
  padding: 0;
}

.react-tags__list-item {
  display: inline;
  list-style: none;
}

.react-tags__tag {
  margin: 0 0.25rem 0.25rem 0;
  background-color: black !important;
  color: white !important;
  border: none !important;
  font-size: medium !important;
  border-radius: 8.08px !important;
  padding: 8px 12px !important;
  text-transform: capitalize !important;
}

.react-tags__tag:hover {
  color: #ffffff;
  background-color: #4f46e5;
}

.react-tags__tag::after {
  content: "";
  display: inline-block;
  width: 0.65rem;
  height: 0.65rem;
  clip-path: polygon(
    10% 0,
    0 10%,
    40% 50%,
    0 90%,
    10% 100%,
    50% 60%,
    90% 100%,
    100% 90%,
    60% 50%,
    100% 10%,
    90% 0,
    50% 40%
  );
  margin-left: 0.5rem;
  font-size: 0.875rem;
  background-color: white !important;
}

.react-tags__tag:hover::after {
  background-color: #ffffff;
}

.react-tags__combobox {
  display: inline-block;
  /* match tag layout */
  padding: 0.375rem 0.25rem;
  /* margin-bottom: 0.25rem; */
  /* prevents autoresize overflowing the container */
  max-width: 100%;
}

.react-tags__combobox-input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;
  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  background: none;
  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__combobox-input::placeholder {
  color: #7c7d86;
  opacity: 1;
}

.react-tags__listbox {
  position: absolute;
  /* max-height: 320px !important;
  overflow-y: scroll !important;  */
  z-index: 1;
  top: calc(100% + 5px);
  /* Negate the border width on the container */
  left: -2px;
  right: -2px;
  max-height: 9rem;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 6px;
  padding: 10px !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0 10px 15px -4px,
    rgba(0, 0, 0, 0.05) 0 4px 6px -2px;
}

.react-tags__listbox-option {
  padding: 10px !important;
  box-shadow: 0px 4px 6px -2px #10182808 !important;
  text-transform: capitalize;
}

.react-tags__listbox-option:hover {
  cursor: pointer;
  background: #f9fafb;
  color: black !important;
  border-radius: 6px !important;
}

.react-tags__listbox-option:not([aria-disabled="true"]).is-active {
  background: #f9fafb;
  color: black;
}

.react-tags__listbox-option[aria-disabled="true"] {
  cursor: not-allowed;
  pointer-events: none;
  color: black !important;
  background-color: #f9fafb !important;
  border-radius: 6px !important;
}

.react-tags__listbox-option[aria-selected="true"]::after {
  content: "✓";
  margin-left: 0.5rem;
  color: black;
}

.react-tags__listbox-option[aria-selected="true"]:not(.is-active)::after {
  color: #4f46e5;
}

.react-tags__listbox-option-highlight {
  background-color: #ffdd00;
}

/* width */
.react-tags__listbox::-webkit-scrollbar {
  width: 8px !important;
}

/* Track */
.react-tags__listbox::-webkit-scrollbar-track {
  box-shadow: 0px !important;
  border-radius: 4px !important;
}

/* Handle */
.react-tags__listbox::-webkit-scrollbar-thumb {
  background: #eaecf0 !important;
  border-radius: 4px !important;
}

/* Handle on hover */
.react-tags__listbox::-webkit-scrollbar-thumb:hover {
  background: #eaecf0 !important;
}

.with-highlight {
  color: white;
}

.hightlight-text-container {
  overflow: hidden !important;
  position: relative !important;
  /* background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%); */
}

.hightlight-text-container::after {
  position: absolute !important;
  content: "" !important;
  left: 0px !important;
  top: 40% !important;
  height: 80% !important;
  width: 100% !important;
  background: linear-gradient(transparent, black) !important;
  pointer-events: none !important;
}

.css-bhj4ar-MuiSlider-root{
  height: 5px !important;
}

.css-1jdkdog-MuiSlider-thumb {
  height: 11px !important;
  width: 11px !important;
}
