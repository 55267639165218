@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "san francisco", sans-serif, "Satoshi";
}

.custom-gradient {
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 21.84%,
    rgba(255, 255, 255, 0.88) 29.51%,
    #ffffff 72.99%
  );
}

.inner-shadow {
  box-shadow: inset -4px -4px 5px rgba(0, 0, 0, 0.28);
}

.custom-drop-shadow {
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.35);
}
