/* ul li {
    font-size: 1.5rem !important;
}

.main-ClockContainer {
    gap: 20px !important;
    border-bottom: none !important;
    font-size: 1.5rem !important;
}

.react-ios-time-picker-container {
    width: 300px !important;
}

.react-ios-time-picker-popup {
    width: 100% !important;
    position: absolute !important;
    bottom: 38% !important;
    left: 0% !important;
}


.react-ios-time-picker-btn {
    color: white !important;
}

.blackborder {
    border: 1px solid rgb(0, 0, 0) !important;
} */

.MuiTimeField-root{
    font-size: xx-large !important;
    border: 0 !important;
}
.MuiInputBase-input{
    font-size: xx-large !important;
    text-align: center;
    border: 0;
}