.slick-track,
.slick-list,
.slick-slide {
  height: 100% !important;
}

.slick-slide div {
  height: 100%;
}

.slider-dots {
  width: 100%;
  margin: 0;
  padding: 0;
  padding-bottom: 2px;
  text-align: center;
  list-style: none;

  li {
    display: inline-block;
    width: calc(100% / var(--total-items) - 8px);
    margin: 0 2px;
  }

  button {
    position: relative;
    width: 100%;
    height: 4px;
    border: 0;
    background-color: #3b3a35;
    font-size: 0;

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      display: block;
      width: 0%;
      height: 100%;
    }
  }

  .slick-active {
    button:after {
      background-color: white;
      animation: progress 10s linear forwards;
    }
  }
}

@keyframes progress {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}
.update-indicator {
  color: #ff0000; /* Set the color to your preference */
  font-size: 1.2em; /* Set the font size to your preference */
  opacity: 0;
  transition: opacity 1s ease-out;
}

.animate__flash .update-indicator {
  opacity: 1;
}

progress::-webkit-progress-value {
  transition : width 3s ease;
}

.progress-glow {
  box-shadow: 0 0 5px rgba(0, 255, 0, 0.8);
}



